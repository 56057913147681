<template>
  <div class="l-wrapper">
    <div class=" q-pa-md q-gutter-sm">
      <q-select
        outlined
        :options="data.year"
        v-model="data.selDate.year"
        label="Year"
      ></q-select>
      <q-select
        outlined
        map-options
        emit-value
        :options="data.month"
        v-model="data.selDate.month"
        label="Month"
      ></q-select>
    </div>
    <q-scroll-area class="l-grow">
      <q-list separator>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in data.monthly_sales"
          :key="i"
          v-ripple
        >
          <q-item-section>
            <div class="column q-gutter-sm">
              <q-item-label>{{ val.username }}</q-item-label>
              <q-input
                dense
                outlined
                class="bg-white"
                label="Qty"
                v-model="val.qty"
              ></q-input>
              <q-field outlined dense v-model="val.value" class="bg-white">
                <template
                  v-slot:control="{ id, floatingLabel, modelValue, emitValue }"
                >
                  <money3
                    :id="id"
                    class="q-field__input"
                    :model-value="modelValue"
                    @update:model-value="emitValue"
                    v-bind="config"
                    v-show="floatingLabel"
                  ></money3>
                </template>
              </q-field>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn label="Save" color="primary" @click="updateMonthlySales"></q-btn>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Money3Component } from "v-money3";
import useMonthlySales from "./useMonthlySales";
export default {
  components: {
    money3: Money3Component,
  },
  setup() {
    const {
      data,
      getDetail,
      route,
      updateMonthlySales,
      $q,
    } = useMonthlySales();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        data.selDate = { year: route.params.year, month: route.params.month };
        await getDetail(route.params.year, route.params.month);
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    let config = ref({
      prefix: "Rp.",
      suffix: "",
      thousands: ",",
      decimal: ".",
      precision: 0,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
    });

    return {
      data,
      updateMonthlySales,
      route,
      config,
    };
  },
};
</script>
