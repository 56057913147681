import { reactive, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import Notify from 'quasar/src/plugins/Notify.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
export default function useUser() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const $q = useQuasar();

  let data = reactive({
    monthly_sales: [],

    year: ["2021", "2022", "2023", "2024", "2025", "2026"],
    selYear: 2021,
    availYear: [],

    month: [
      { label: "Januari", value: "1" },
      { label: "Februari", value: "2" },
      { label: "Maret", value: "3" },
      { label: "April", value: "4" },
      { label: "Mei", value: "5" },
      { label: "Juni", value: "6" },
      { label: "Juli", value: "7" },
      { label: "Agustus", value: "8" },
      { label: "September", value: "9" },
      { label: "Oktober", value: "10" },
      { label: "November", value: "11" },
      { label: "Desember", value: "12" },
    ],

    selDate: {},

    curUser: {
      id: 1,
    },
  });

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
  });

  let getMonthlySales = async () => {
    let resp = await $http.get(`/monthly-sales/list/${data.selYear}`);
    for (let item of resp.data) {
      item.my = `${moment(item.month).format("MMM")} - ${moment(
        item.year
      ).format("YYYY")}`;
      item.value = formatter.format(item.value);
    }
    data.monthly_sales = resp.data;
  };

  let getYear = async () => {
    let temp = [];
    let resp = await $http.get(`/monthly-sales/year`);
    for (let item of resp.data) {
      temp.push(item.year);
    }
    data.availYear = temp;
  };

  let addMonthlySales = async () => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      for (let item of data.monthly_sales) {
        item.year = data.selDate.year;
        item.month = data.selDate.month;
      }
      await $http.post(`/monthly-sales/create`, data.monthly_sales);
      router.push("/monthly-sales/list");
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let getSales = async () => {
    let resp = await $http.get(`/user/list-sales`);
    for (let item of resp.data) {
      item.label = item.username;
      item.value = 0;
    }
    data.monthly_sales = JSON.parse(JSON.stringify(resp.data));
  };

  let getDetail = async (year, month) => {
    let resp = await $http.get(`/monthly-sales/detail/${year}/${month}`);
    for (let item of resp.data) {
      item.value = parseInt(item.value);
    }
    data.monthly_sales = JSON.parse(JSON.stringify(resp.data));
  };

  let updateMonthlySales = async () => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      for (let item of data.monthly_sales) {
        item.year = data.selDate.year;
        item.month = data.selDate.month;
      }
      await $http.post(`/monthly-sales/update`, data.monthly_sales);
      router.push("/monthly-sales/list");
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  return {
    data,
    router,
    route,
    getMonthlySales,
    addMonthlySales,
    getSales,
    getDetail,
    updateMonthlySales,
    getYear,
    $q,
  };
}
